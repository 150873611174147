import React from "react";
import "./loader.scss";


// LOADER
function Loader(){
	
	// Loader View
  return(
		<div className="loader-container">
			<div className="loader">
			</div>
		</div>
  )
	
}

export default Loader;